<template>
  <div class="waterfallItem">
    <div class="waterfall">
      <div
        class="photoBox"
        v-for="(item, index) in list"
        :key="item.id"
        :style="computedRatio(item)"
        @click="openDetails(list, item, index)"
      >
        <div class="coverBox" v-if="item.verticalCover || item.horizontalCover">
          <ImgDecypt
            class="cover"
            :src="
              item.direction == 1 ? item.verticalCover : item.horizontalCover
            "
            :key="
              item.direction == 1 ? item.verticalCover : item.horizontalCover
            "
            :style="{ height: computedCoverHeight(item) + 'px' }"
          />
          <div class="label" :class="handelLabel(item.permission)"></div>
          <div class="statusBox" v-if="item.kind != 5">
            {{ item.updateStatus == 2 ? "已完结·" : "更新中·"
            }}{{ item.totalEpisode }}话
          </div>
        </div>
        <div class="videoInfoBox">
          <div class="videoTitle">{{ item.title }}</div>
          <div class="dataBox" v-if="item.kind == 5">
            <div class="countBox">
              <SvgIcon iconClass="newPlayIcon" class="dataIcon"></SvgIcon>
              {{ item.countBrowse | watchCount }}
            </div>
            <div class="countBox">
             <SvgIcon iconClass="collect" class="dataIcon"></SvgIcon>
             {{ item.countCollect | watchCount }}
            </div>
            <div
              class="countBox"
              v-if="item.contents && item.contents.length > 0"
            >
              <SvgIcon iconClass="imgIcon" class="dataIcon"></SvgIcon>
              {{
                (item.contents[0].urlSet && item.contents[0].urlSet.length)
                  | watchCount
              }}
              <!-- {{ item.contents[0].urlSet.length }} -->
            </div>
          </div>
          <div v-else-if="!item.adv && item['tagNames']">
            <span
              v-for="(itemTag, indexTag) in item['tagNames'].slice(0, 3)"
              :key="indexTag"
              class="videoTxtTag"
              :class="item.activeTagName === itemTag ? 'activeTag' : ''"
              @click.stop="tagJump(item, indexTag, itemTag)"
            >
              <span>
                {{ itemTag }}
              </span>
              <br v-if="(indexTag + 1) % 3 == 0" />
            </span>
          </div>
          <div v-else-if="!item.adv && item['tagDetails']">
            <span
              v-for="(itemTag, indexTag) in item['tagDetails'].slice(0, 3)"
              :key="indexTag"
              class="videoTxtTag"
              :class="item.activeTagName === itemTag.name ? 'activeTag' : ''"
              @click.stop="tagJump(item, indexTag, itemTag.name)"
            >
              <span>
                {{ itemTag.name }}
              </span>
              <br v-if="(indexTag + 1) % 3 == 0" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import SvgIcon from "@/components/SvgIcon";
import store from "@/store/index.js";
import {openDetails} from "@/utils/getConfig";
export default {
  name: "waterfallItem",
  props: {
    list: {
      type: Array,
    },
  },
  components: {
    ImgDecypt,
    SvgIcon,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    getRandomInRange() {
      return (min, max) => {
        return Math.random() * (max - min) + min;
      };
    },
    computedRatio() {
      let height = 12;
      return (item) => {
        return {
          gridRowEnd: `span ${Math.floor(
            this.computedCoverHeight(item) / 4.9 + height
          )}`,
        };
      };
    },
  },
  methods: {
   openDetails,
    computedCoverHeight(item) {
      if (item.direction == 1) {
        return 250;
      } else {
        return 94;
      }
    },
    handelLabel(permission) {
      if (permission == 1) {
        return "goldLabelIcon";
      } else if (permission == 2) {
        return "freeLabelIcon";
      } else if (permission == 0) {
        return "vipLabelIcon";
      }
    },
    jump(item, index) {
      // console.log(this.$route);
      // console.log(item);
      let kind = item["kind"];
      if (kind != null) {
        switch (kind) {
          case 0:
          case 2:
          case 3:
          case 6:
            //跳转视频
            this.$router.push({
              path: "/videoDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                fromTagList: this.$route.path == "/tagList" ? true : false,
              },
            });
            break;
          case 4:
          case 1:
            store.commit("pageRefresh/SET_REFRESHFLAG", {
              name: "novelDetailsPage",
              flag: true,
            });
            //小说/漫画
            this.$router.push({
              path: "/novelDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                fromTagList: this.$route.path == "/tagList" ? true : false,
                price: item.price,
              },
            });
            break;
          case 7:
            store.commit("pageRefresh/SET_REFRESHFLAG", {
              name: "novelDetailsPage",
              flag: true,
            });
            // 有声小说详情
            this.$router.push({
              path: "/audioBookDetails",
              query: {
                id: item.id,
                kind: kind,
                fromTagList: this.$route.path == "/tagList" ? true : false,
                price: item.price,
              },
            });
            break;
          case 5:
            var list = JSON.stringify(this.videoList);
            //图集
            this.$router.push({
              path: "/portrayPage",
              query: {
                id: item.id,
                kind: item.kind,
                list: this.encodeBase64(list),
                phoneIndex: index,
                fromTagList: this.$route.path == "/tagList" ? true : false,
              },
            });
            break;
        }
      }
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>
<style scoped lang="scss">
.waterfallItem {
  // padding-top: 10px;
  .waterfall {
    // padding: 0 10px;
    display: grid;
    grid-auto-rows: 5px;
    grid-template-columns: repeat(auto-fill, 174px);
    align-items: start;
    justify-content: space-between;

    .photoBox {
      width: 174px;
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;
      border-radius: 8px;
      .coverBox {
        position: relative;
        .label {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 20px;
        }
        .goldLabelIcon {
          background: url("../../assets/png/goldLabelIcon.png");
          background-size: 100% 100%;
        }
        .freeLabelIcon {
          background: url("../../assets/png/freeLabelIcon.png");
          background-size: 100% 100%;
        }
        .vipLabelIcon {
          background: url("../../assets/png/vipLabelIcon.png");
          background-size: 100% 100%;
        }
        .statusBox {
          position: absolute;
          right: 5px;
          bottom: 5px;
          padding: 0 9px;
          box-sizing: border-box;
          height: 20px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(1.0499999523162842px);
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          color: #3e169d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .videoInfoBox {
        padding: 4px 5px 10px;
        box-sizing: border-box;
        .videoTitle {
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          width: 163px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .dataBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 4px;
          font-family: "PingFang SC";
          font-size: 10px;
          .countBox {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .dataIcon {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
        .videoTxtTag {
          margin-top: 4px;
          font-size: 10px;
          font-weight: 400;
          color: #999;
          margin-right: 6px;
        }
      }

      .cover {
        width: 173px;
        border-radius: 8px;
        overflow: hidden;
      }

      .titleBox {
        width: 174px;

        .title {
          padding-top: 4px;
          width: 174px;
          color: #000000;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          word-wrap: break-word;

          span {
            padding-left: 5px;
          }
        }

        .info {
          align-items: center;
          padding: 0 5px 7px;

          .tags {
            display: flex;
            flex-wrap: wrap;
            font-size: 10px;
            color: #999;
            margin-top: 5px;

            .tag {
              margin-right: 6px;
            }
          }

          span {
            font-size: 10px;
            color: #777777;
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>
